import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Closing large LOIs quickly`}</h2>
    <p>{`Contrary to what other founders and investors might tell you, it is `}<strong parentName="p">{`definitely possible`}</strong>{` to get LOIs or design partners in early stages of a startup. The shortest timeframe I've seen from first conversation to a signed $10m+ LOI is 3-4 weeks. More commonly it takes 2-3 months.`}</p>
    <h2>{`Principles behind closing LOI's fast`}</h2>
    <ul>
      <li parentName="ul">{`Companies are more likely to sign LOIs if your eventual product will meaningfully impact their core revenue streams.`}</li>
      <li parentName="ul">{`Companies are more likely to sign LOIs if they trust you, either through personal connections or because of your on-paper background.`}</li>
      <li parentName="ul">{`The largest companies (eg. big pharma) will be least likely to act fast. The challenger companies (eg. $1b-10b valuation companies) will be more likely to make early bets on things that can give them an edge against the big ones. Startups will act fastest but their LOIs are worth less. Startups are useful to build momentum to convince challenger companies.`}</li>
      <li parentName="ul"><strong parentName="li">{`Getting a fast no is better than a slow conversation.`}</strong>{` Usually a "no" to an LOI really means "not right now", and you can always approach the company again once you've shown more progress.`}</li>
      <li parentName="ul">{`Demonstrating momentum and caliber of team is more critical than defending your technical progress. Any company signing an LOI knows there is significant technical risk, which is why LOIs are non-binding.`}</li>
    </ul>
    <h2>{`Tactics to close LOIs in 1-2 months`}</h2>
    <ul>
      <li parentName="ul">{`Target companies where your product will impact their core revenue streams. `}<strong parentName="li">{`Tell them a story of how it will help improve their bottom line or solve a core problem they face.`}</strong>{` Don't assume they will put the pieces together, spell it out for them.`}</li>
      <li parentName="ul">{`Target companies where you have strong personal connections or can `}<strong parentName="li">{`get warm intros to senior decision-makers`}</strong>{`. If you're in touch with a junior contact, try to find a warm senior contact in parallel to accelerate the conversation. A strong internal champion with enough authority is critical.`}</li>
      <li parentName="ul">{`Close a couple $5-10k LOIs quickly. Use this as social proof to get $500k-1m LOIs before pushing the $1m+ LOIs to close.`}</li>
      <li parentName="ul">{`Start conversations ASAP with large companies as they will need more time to close.`}</li>
      <li parentName="ul"><strong parentName="li">{`Set deadlines early on in the conversations.`}</strong>{` Ideally, the deadline is within a couple months. Asking for a concrete deadline will force the other party to signal how serious they are about working with you now rather than waiting 6-12 months. Without a deadline, the customer will prioritize work from the 50 other people who are asking them for things by a deadline. The biggest companies will often ignore the deadline (because they know you won't go away), but challengers and startups will treat it seriously. If companies ask to push the deadline more than 3 months out, move on and tell them you can pick up the conversation in a few months.`}</li>
      <li parentName="ul"><strong parentName="li">{`Create scarcity`}</strong>{`. Tell prospective customers you are only taking on 5 pilot partners who will get XXX benefit for being the early partner. This gives challenger companies an extra incentive to act now.`}</li>
      <li parentName="ul">{`Follow up as you approach the deadline with updates on your traction and momentum. `}<strong parentName="li">{`"We received inbound interest from 40 investors and are accelerating our fundraise`}</strong>{`" or "we just closed LOIs with X and Y companies and only have 2 spots left" or "we just hit X technical milestone".`}</li>
      <li parentName="ul">{`Make clear how signing an LOI will help you close investors and ultimately deliver your product to them faster. If your product is meaningful to your customer, that means `}<strong parentName="li">{`you are both on the same team`}</strong>{`. "Having a signed LOI from you will help us raise more money and fast-track our scientific progress."`}</li>
      <li parentName="ul">{`Remind them that this is a non-binding agreement. You're confident that you're going to make progress, but they aren't on the hook if the product you ship doesn't meet their needs. "Yeah we are a bit early, that's why we aren't signing a binding agreement. This gives you a free limited option to be one of our preferred partners."`}</li>
      <li parentName="ul">{`Offer public-facing confidentiality if they are hesitant. It's most critical that you can share LOI info with investors, less important to share it publicly.`}</li>
      <li parentName="ul"><strong parentName="li">{`Ensure dollar amounts on the LOI`}</strong>{`. Treat this as non-negotiable, as LOIs without dollar amounts are meaningless. An LOI that states price per unit + minimum number of units is acceptable. LOI's worth <$100k are relatively meaningless to investors, but can be helpful to build LOI momentum.`}</li>
      <li parentName="ul">{`Demonstrate that you are an expert in the space. Do research on how your technology fits into the broader ecosystem. Demonstrate your expertise when answering their questions in meetings or by sharing an industry outlook or whitepaper with prospective customers.`}</li>
      <li parentName="ul">{`Pitch confidently with strong language. Don't haircut your progress with your own language or get caught up in technicalities. You're talking to business people, not scientists. They are far less nit-picky.`}</li>
      <li parentName="ul">{`Be optimistic. If you don't feel confident that you can close LOIs in a few months, it's definitely not going to happen.`}</li>
      <li parentName="ul">{`Manage your morale through the slow process and the "no's". Sales can feel much more frustrating than science/tech since it's less in your control. `}<strong parentName="li">{`If you build a big funnel and pursue it relentlessly, you'll get results.`}</strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      